/* import vue */

import Vue from 'vue';
import Main from './Main.vue';

export default class StatusPageView {
	constructor(wrapper) {
		this.wrapper = wrapper;
		this.initVue();
	}

	initVue() {
		new Vue({
			el: this.wrapper,
			render(h) {
				return h(
					Main,
					{
						props: {
							// pass props here
						}
					}
				)
			}
		});
	}
}
